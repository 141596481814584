import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FiCopy, FiCheck, FiLoader, FiExternalLink } from 'react-icons/fi';
import { useWeb3Auth, Web3AuthState } from '../hooks/useWeb3Auth';

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
`;

const PrivateKeyText = styled.span`
    flex: auto;
    line-height: 20px;
    word-break: break-all;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-left: 8px;
`;

const ExternalLinkIconWrapper = styled(FiExternalLink)`
    margin-left: 4px;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderIcon = styled(FiLoader)`
    animation: ${spin} 1s linear infinite;
`;

interface PrivateKeyTextDisplayProps {
    privateKeyAvailable: boolean;
    authState: Web3AuthState;
}

/**
 * プライベートキーのテキスト表示コンポーネント
 *
 * @param props.privateKeyAvailable - プライベートキーが利用可能かどうか
 * @param props.authState - 現在の認証状態
 */
const PrivateKeyTextDisplay: React.FC<PrivateKeyTextDisplayProps> = ({
    privateKeyAvailable,
    authState,
}) => (
    <PrivateKeyText>
        {authState !== Web3AuthState.DISCONNECTED &&
            privateKeyAvailable &&
            '********************'}
    </PrivateKeyText>
);

interface CopyButtonProps {
    authState: Web3AuthState;
    handleConnect: () => void;
    handleCopy: () => void;
}

/**
 * プライベートキーのコピーボタンコンポーネント
 *
 * @param props.authState - 現在の認証状態
 * @param props.handleConnect - 接続処理を行うコールバック関数
 * @param props.handleCopy - コピー処理を行うコールバック関数
 */
const CopyButton: React.FC<CopyButtonProps> = ({
    authState,
    handleConnect,
    handleCopy,
}) => {
    if (authState === Web3AuthState.LOADING) {
        return <LoaderIcon />;
    }

    return (
        <ButtonWrapper>
            {authState === Web3AuthState.DISCONNECTED ? (
                <StyledButton onClick={handleConnect}>
                    Web3Authに接続して取得する
                    <ExternalLinkIconWrapper />
                </StyledButton>
            ) : (
                <StyledButton onClick={handleCopy}>
                    {authState === Web3AuthState.CONNECTED && <FiCopy />}
                    {authState === Web3AuthState.COPIED && <FiCheck />}
                </StyledButton>
            )}
        </ButtonWrapper>
    );
};

/**
 * プライベートキーを表示・管理するセクションコンポーネント
 *
 * @returns プライベートキーの表示と操作UIを含むReactコンポーネント
 *
 * @example
 * <PrivateKeySection />
 */
const PrivateKeySection: React.FC = () => {
    const { authState, privateKey, connect, copyPrivateKey } = useWeb3Auth();

    return (
        <RowWrapper>
            <PrivateKeyTextDisplay
                privateKeyAvailable={Boolean(privateKey)}
                authState={authState}
            />
            <CopyButton
                authState={authState}
                handleConnect={connect}
                handleCopy={copyPrivateKey}
            />
        </RowWrapper>
    );
};

export default PrivateKeySection;
