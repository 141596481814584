import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3Auth } from './useWeb3Auth';
import CookieManager from '../lib/cookieManager';
import SessionStorageManager from '../lib/sessionStorageManager';
import { PATH_MAPPING } from '../constants/pathMapping';

export type LogoutFunction = (dialogMessage?: string) => Promise<void>;

/**
 * ログアウト処理を行う
 * @returns {function} ログアウト関数
 */
const useLogout = (): LogoutFunction => {
    const navigate = useNavigate();
    const { logout: web3authLogout } = useWeb3Auth();

    const logout = useCallback(
        async (dialogMessage?: string) => {
            // Web3Authからログアウト
            await web3authLogout();

            // 参照できるCookieをすべて削除する
            CookieManager.deleteAllCookies();
            // 参照できるSessionStorageをすべて削除する
            SessionStorageManager.deleteAllSessionStorageValues();

            // ルートのパスに遷移
            if (!dialogMessage) {
                navigate(PATH_MAPPING.ROOT);
                return;
            }

            navigate(PATH_MAPPING.ROOT, {
                state: {
                    message: dialogMessage,
                },
            });
        },
        [navigate, web3authLogout]
    );

    return logout;
};

export default useLogout;
