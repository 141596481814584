import axios from 'axios';
import { getWeb3authInstance } from './getWeb3authInstance';

/**
 * Web3Authからプライベートキーを取得する
 *
 * @returns プライベートキーの文字列、取得失敗時はnull
 *
 * @example
 * const privateKey = await fetchPrivateKey();
 * if (privateKey) {
 *     // プライベートキーを使用した処理
 * }
 */
export const fetchPrivateKey = async (): Promise<string | null> => {
    try {
        const web3auth = await getWeb3authInstance();
        if (!web3auth) {
            console.error('Failed to fetch web3auth instance');
            return null;
        }

        // NOTE: providerもaccountsも取得に失敗することは基本ないが、取得できない場合にnullを返す
        const provider = web3auth.provider;
        if (!provider) {
            console.error('Failed to fetch provider');
            return null;
        }

        const accounts = await provider
            .request({ method: 'eth_accounts' })
            .catch((error) => {
                console.error('Failed to fetch accounts:', error);
                return null;
            });
        if (!accounts) {
            return null;
        }

        const privateKey = await provider
            .request({
                method: 'eth_private_key',
            })
            .catch((error) => {
                console.error('Failed to fetch private key:', error);
                return null;
            });

        if (!privateKey) {
            console.error('Private key request returned null');
            return null;
        }

        return privateKey as string;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error(
                'Axios error:',
                error.response ? error.response.data : error.message
            );
        } else {
            console.error('Unexpected error fetching private key:', error);
        }
        return null;
    }
};
