import { initializeWeb3Auth } from './initializeWeb3Auth';
import { Web3AuthNoModal } from '@web3auth/no-modal/dist/types/noModal';

/*
 * web3authと接続状態にあるならば、そのインスタンスを返す。
 * @returns {Promise<Web3AuthNoModal>} Web3AuthNoModal | undefined
 */
export const getWeb3authInstance = async (): Promise<
    Web3AuthNoModal | undefined
> => {
    const web3auth: Web3AuthNoModal = await initializeWeb3Auth();

    if (web3auth.connected) {
        return web3auth;
    }
    return undefined;
};
