/**
 * 画像パスを正しく解決するユーティリティ関数
 *
 * @param path - 画像の相対パスまたは絶対パス
 * @returns 正しく解決された画像パス
 *
 * @example
 * // 空文字列の場合
 * resolveImagePath('') // returns: ''
 *
 * // 絶対URLの場合
 * resolveImagePath('https://example.com/images/logo.png')
 * // returns: 'https://example.com/images/logo.png'
 *
 * // 相対パスの場合
 * resolveImagePath('/images/icon.png')
 * // returns: '/base-url/images/icon.png'
 */
export const resolveImagePath = (path: string | undefined): string => {
    if (!path) return '';

    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }

    const baseUrl = import.meta.env.BASE_URL || '/';
    return path.startsWith('/')
        ? `${baseUrl}${path.slice(1)}`
        : `${baseUrl}${path}`;
};
