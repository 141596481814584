import { useState, useEffect, useCallback } from 'react';
import { fetchPrivateKey } from '../lib/web3auth/fetchPrivateKey';
import { getWeb3authInstance } from '../lib/web3auth/getWeb3authInstance';
import { connectToWeb3Auth } from '../lib/web3auth/connectToWeb3Auth';

export enum Web3AuthState {
    DISCONNECTED = 'disconnected',
    CONNECTED = 'connected',
    LOADING = 'loading',
    COPIED = 'copied',
}

/**
 * Web3Authの状態を管理するカスタムフック
 *
 * @returns {Object} Web3Authの状態と操作関数
 * @property {Web3AuthState} authState - 現在の認証状態
 * @property {string | null} privateKey - プライベートキー
 * @property {() => Promise<void>} connect - Web3Authへの接続を行う関数
 * @property {() => Promise<void>} copyPrivateKey - プライベートキーをクリップボードにコピーする関数
 *
 * @example
 * const { authState, privateKey, connect, copyPrivateKey } = useWeb3Auth();
 */
export const useWeb3Auth = () => {
    const [authState, setAuthState] = useState<Web3AuthState>(Web3AuthState.LOADING);
    const [privateKey, setPrivateKey] = useState<string | null>(null);

    useEffect(() => {
        const initializeWeb3AuthInstance = async () => {
            try {
                const web3auth = await getWeb3authInstance();
                if (web3auth) {
                    const key = await fetchPrivateKey();
                    if (key) {
                        setPrivateKey(key);
                        setAuthState(Web3AuthState.CONNECTED);
                    } else {
                        setAuthState(Web3AuthState.DISCONNECTED);
                    }
                } else {
                    setAuthState(Web3AuthState.DISCONNECTED);
                }
            } catch (error) {
                console.error('Error initializing Web3Auth instance:', error);
                setAuthState(Web3AuthState.DISCONNECTED);
            }
        };
        initializeWeb3AuthInstance();
    }, []);

    const connect = useCallback(async () => {
        try {
            const web3auth = await connectToWeb3Auth(undefined, true);
            if (web3auth) {
                const key = await fetchPrivateKey();
                if (key) {
                    setPrivateKey(key);
                    setAuthState(Web3AuthState.CONNECTED);
                } else {
                    setAuthState(Web3AuthState.DISCONNECTED);
                }
            } else {
                setAuthState(Web3AuthState.DISCONNECTED);
            }
        } catch (error) {
            console.error('Error connecting to Web3Auth:', error);
            setAuthState(Web3AuthState.DISCONNECTED);
        }
    }, []);

    const copyPrivateKey = useCallback(async () => {
        if (!privateKey) return;
        setAuthState(Web3AuthState.LOADING);
        try {
            await navigator.clipboard.writeText(privateKey);
            setAuthState(Web3AuthState.COPIED);
            setTimeout(() => {
                setAuthState(Web3AuthState.CONNECTED);
            }, 2000);
        } catch (err) {
            console.error('Could not copy text:', err);
            setAuthState(Web3AuthState.CONNECTED);
        }
    }, [privateKey]);

    /**
     * Web3Authからログアウトする関数
     *
     * @returns {Promise<void>}
     */
    const logout = useCallback(async () => {
        try {
            const web3auth = await getWeb3authInstance();
            if (web3auth) {
                await web3auth.logout();
                setPrivateKey(null);
                setAuthState(Web3AuthState.DISCONNECTED);
            }
        } catch (error) {
            console.error('Error logging out from Web3Auth:', error);
        }
    }, []);

    return {
        authState,
        privateKey,
        connect,
        copyPrivateKey,
        logout
    };
};