import { WALLET_ADAPTERS } from '@web3auth/base';
import { initializeWeb3Auth } from './initializeWeb3Auth';
import { Web3AuthNoModal } from '@web3auth/no-modal/dist/types/noModal';
import { handleTokenRefresh } from '../tokenRefresh';
import { TokenManager } from '../tokenManager';

/*
 * カスタムjwt認証を用いてWeb3Authと接続する。
 * @param {string} jwt jwt
 * @returns {Promise<Web3AuthNoModal>} Web3AuthNoModal
 */
export const connectToWeb3Auth = async (
    nonce: string | undefined = undefined,
    isTokenRefresh: boolean = false
): Promise<Web3AuthNoModal | undefined> => {
    const web3auth: Web3AuthNoModal = await initializeWeb3Auth();

    if (web3auth.connected) {
        return web3auth;
    }

    //  NOTE: ノード上(web3)でのトークン重複エラーの対策
    if (isTokenRefresh) {
        await handleTokenRefresh();
    }
    const refreshedJwt: string = TokenManager.getAccessToken() ?? '';

    // Note: connectToをコールすると、Redirectが走る。
    // ここでリダイレクト先にnonceを仕込む
    const url = new URL(window.location.href);
    url.searchParams.append('nonce', nonce ?? '');
    const redirectUrl = url.toString();
    await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'jwt',
        redirectUrl: redirectUrl,
        extraLoginOptions: {
            id_token: refreshedJwt,
            verifierIdField: 'sub', // sub, email, or custom
        },
    });

    return;
};
